import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Callout, { ForOfficeCallout } from '../components/public/callout';
import Pricing from '../components/public/pricing';
import Faq from '../components/public/faq';
import SEO from '../components/seo';

const Page = () => (
	<Layout>
		<SEO
			title="Block websites and filter internet at work"
			description={`Block unwanted content or monitor internet activity at your office by switching to our DNS servers. Take control of your internet experience.`}
		/>

		<Hero title={'Internet filtering for offices'} style={{
			marginBottom: 50,
		}}/>

		<ForOfficeCallout style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default Page;
